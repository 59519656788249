import React, { useRef } from "react";
import { Row, Col, Avatar, Typography, Descriptions, Tag, Button } from "antd";
import styled from "styled-components";
import {
  UserOutlined,
  CaretRightOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import ReactToPrint from "react-to-print";
import { useQuery } from "@apollo/react-hooks";

import {
  color,
  details,
  types,
  titles,
  titleAchievements,
} from "@helpers/const";

import { queries } from "@helpers/queryStrings";

import SEO from "@components/common/seo";

import avatarImg from "@images/commons/me.png";

const { Title, Paragraph, Text } = Typography;

const paddingSet = "37.5px";
const margintSet = "15px";
const Home = () => {
  const componentRef = useRef();
  const { data } = useQuery(queries.user_test);
  console.log(data);
  return (
    <>
      <SEO title="Resume" />
      {/* <PageHeader title="Home" subTitle="Overall" /> */}
      <Row style={{ margin: "35px 0" }}>
        <Col span={24} style={{ textAlign: "left" }}>
          <Button type="link">
            <Title>
              <a href="https://dayrlism.info">
                <ArrowLeftOutlined />
              </a>
            </Title>
          </Button>
        </Col>
        <Col span={24} style={{ textAlign: "center" }}>
          <ReactToPrint
            trigger={() => <Button type="primary">PRINT RESUME</Button>}
            content={() => componentRef.current}
          />
        </Col>
      </Row>
      <Resume ref={componentRef} />
      <Row style={{ margin: "35px 0" }}>
        <Col span={24} style={{ textAlign: "center" }}>
          <ReactToPrint
            trigger={() => <Button type="primary">PRINT RESUME</Button>}
            content={() => componentRef.current}
          />
        </Col>

        <Col span={24} style={{ textAlign: "center", marginTop: 20 }}>
          <Button type="link">
            <Title level={4}>
              <a href="https://dayrlism.info">BACK TO MAIN</a>
            </Title>
          </Button>
        </Col>
      </Row>
    </>
  );
};

class Resume extends React.Component {
  render() {
    const loading = false;
    return (
      <HomeWrapper loading={loading.toString()}>
        <Row style={{ padding: `${paddingSet} ${paddingSet} 0 ${paddingSet}` }}>
          <Col span={17} sm={20}>
            <Row>
              <Col span={24}>
                <Title style={{ color: color.whiteText }}>{details.name}</Title>
              </Col>
              <Col span={24}>
                <Title style={{ color: color.secondary }} level={4}>
                  {details.title}
                </Title>
              </Col>
              <Col span={24}>
                <Paragraph style={{ color: color.whiteText }}>
                  {details.bios}
                </Paragraph>
              </Col>
            </Row>
          </Col>
          <Col span={7} sm={4} style={{ textAlign: "right" }}>
            <Avatar
              style={{
                width: "123px",
                height: "123px",
                border: `4.5px solid ${color.secondary}`,
              }}
              src={avatarImg}
              size="large"
              icon={<UserOutlined />}
            />
          </Col>
        </Row>
        <Row
          style={{
            padding: `15px ${paddingSet} ${margintSet} ${paddingSet}`,
            marginTop: margintSet,
            backgroundColor: color.secondary,
          }}
        >
          <Col span={24}>
            <Row>
              {details.personalInfo.map((res, key) => {
                return (
                  <Col key={key} span={24} sm={12} md={12}>
                    <Row>
                      <Col span={2} style={{ marginTop: "1px" }}>
                        <Text style={{ color: color.whiteText }}>
                          {res.icon}
                        </Text>
                      </Col>
                      <Col span={22}>
                        <Text style={{ color: color.whiteText }}>
                          {res.text}
                        </Text>
                      </Col>
                    </Row>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
        <Row
          style={{
            padding: `${margintSet} ${paddingSet}`,
            backgroundColor: color.containerBackground,
          }}
        >
          {details.careers.map((res, key) => {
            if (res.types && res.types === types.one) {
              return (
                <Descriptions
                  key={key}
                  title={
                    <div
                      className="dayrlism-title-color"
                      style={{
                        textTransform: "capitalize",
                      }}
                    >
                      {res.title}
                    </div>
                  }
                >
                  <Descriptions.Item>
                    <div>
                      {res.content.map((get, key) => {
                        return (
                          <Tag
                            key={key}
                            style={{
                              textTransform: "uppercase",
                              marginBottom: 5,
                            }}
                            color={color.secondary}
                          >
                            {get}
                          </Tag>
                        );
                      })}
                    </div>
                  </Descriptions.Item>
                </Descriptions>
              );
            } else if (res.types && res.types === types.two) {
              return (
                <Descriptions
                  key={key}
                  title={
                    <div
                      className="dayrlism-title-color"
                      style={{
                        textTransform: "capitalize",
                      }}
                    >
                      {res.title}
                    </div>
                  }
                >
                  <Descriptions.Item>
                    <Row>
                      {res.content.map((get, key) => {
                        return (
                          <Col
                            key={key}
                            span={
                              res.title === titles.organizations ||
                              res.title === titles.certificates
                                ? 12
                                : 24
                            }
                            sm={12}
                          >
                            <div
                              style={{
                                textTransform: "capitalize",
                                fontWeight: 800,
                              }}
                            >
                              <Text>{get.head}</Text>
                            </div>
                            <div style={{ textTransform: "uppercase" }}>
                              <Text>{get.subhead}</Text>
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  </Descriptions.Item>
                </Descriptions>
              );
            } else if (res.title === titles.languages) {
              return (
                <Descriptions
                  key={key}
                  title={
                    <div
                      className="dayrlism-title-color"
                      style={{
                        textTransform: "capitalize",
                      }}
                    >
                      {res.title}
                    </div>
                  }
                >
                  <Descriptions.Item>
                    <Row>
                      {res.content.map((get, key) => {
                        return (
                          <Col key={key} span={8} sm={8}>
                            <div
                              style={{
                                textTransform: "capitalize",
                                fontWeight: 800,
                              }}
                            >
                              <Text>{get.head}</Text>
                            </div>
                            <div style={{ textTransform: "capitalize" }}>
                              <Text>{get.subhead}</Text>
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  </Descriptions.Item>
                </Descriptions>
              );
            } else if (res.title === titles.education) {
              return (
                <Descriptions
                  key={key}
                  title={
                    <div
                      className="dayrlism-title-color"
                      style={{
                        textTransform: "capitalize",
                      }}
                    >
                      {res.title}
                    </div>
                  }
                >
                  <Descriptions.Item>
                    <Row>
                      {res.content.map((get, key) => {
                        return (
                          <Col key={key} span={24}>
                            <div
                              style={{
                                textTransform: "capitalize",
                                fontWeight: 800,
                              }}
                            >
                              <Text>{get.head}</Text>
                            </div>
                            <div style={{ textTransform: "capitalize" }}>
                              <Text>{get.subhead}</Text>
                            </div>
                            <div style={{ fontStyle: "italic" }}>
                              <Text>{get.date}</Text>
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  </Descriptions.Item>
                </Descriptions>
              );
            } else if (res.types && res.types === types.three) {
              return (
                <Descriptions
                  key={key}
                  title={
                    <div
                      className="dayrlism-title-color"
                      style={{
                        textTransform: "capitalize",
                      }}
                    >
                      {res.title}
                    </div>
                  }
                >
                  <Descriptions.Item>
                    <Row>
                      {res.content.map((get, key) => {
                        return (
                          <Col
                            key={key}
                            span={24}
                            style={{ marginTop: "10px" }}
                          >
                            <div
                              style={{
                                textTransform: "capitalize",
                                fontWeight: 800,
                              }}
                            >
                              <Text>{get.title}</Text>
                            </div>
                            <div style={{ textTransform: "capitalize" }}>
                              <Text>{get.company}</Text>
                            </div>
                            <div style={{ fontStyle: "italic" }}>
                              <Text>{get.date}</Text>
                            </div>
                            <div style={{ fontStyle: "italic" }}>
                              <Text>{titleAchievements}</Text>
                            </div>
                            {get.achievements.map((ach, key) => {
                              return (
                                <div
                                  key={key}
                                  style={{ textTransform: "capitalize" }}
                                >
                                  <Text>
                                    <CaretRightOutlined
                                      style={{ marginTop: 4 }}
                                    />
                                    {ach}
                                  </Text>
                                </div>
                              );
                            })}
                          </Col>
                        );
                      })}
                    </Row>
                  </Descriptions.Item>
                </Descriptions>
              );
            } else if (res.types && res.types === types.four) {
              return (
                <Descriptions
                  key={key}
                  title={
                    <div
                      className="dayrlism-title-color"
                      style={{
                        textTransform: "capitalize",
                      }}
                    >
                      {res.title}
                    </div>
                  }
                >
                  <Descriptions.Item>
                    <Row>
                      {res.content.map((get, key) => {
                        return (
                          <Col
                            key={key}
                            span={res.title === titles.references ? 8 : 12}
                            sm={res.title === titles.references ? 8 : 12}
                            style={{ marginTop: "10px" }}
                          >
                            <div
                              style={{
                                textTransform: "capitalize",
                                fontWeight: 800,
                              }}
                            >
                              <Text>{get.head}</Text>
                            </div>
                            <div
                              style={{
                                textTransform: `${
                                  res.title === titles.references
                                    ? "capitalize"
                                    : "lowercase"
                                }`,
                              }}
                            >
                              <Paragraph ellipsis>{get.subhead}</Paragraph>
                            </div>
                            {get.contact && (
                              <div style={{ fontStyle: "italic" }}>
                                <Text>{get.contact}</Text>
                              </div>
                            )}
                          </Col>
                        );
                      })}
                    </Row>
                  </Descriptions.Item>
                </Descriptions>
              );
            }
            return null;
          })}
        </Row>
      </HomeWrapper>
    );
  }
}

export default Home;

const HomeWrapper = styled.div`
  background-color: ${color.primary};
  color: ${color.whiteText};
  max-width: 892.5px;
  margin: 0 auto;
  border: 1px solid black;

  .ant-descriptions-title {
    font-size: 25px;
    margin-bottom: 0px;
  }
  .dayrlism-title-color {
    color: ${color.primary};
    text-decoration: underline;
  }
`;
