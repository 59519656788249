import gql from "graphql-tag";
// import { graphql } from "gatsby";

export const queries = {
  user: gql`
    query user($id: ID!) {
      user(id: $id) {
        id
        username
        email
      }
    }
  `,
  collaboratives: gql`
    query collaboratives {
      collaboratives {
        id
        company {
          text
        }
      }
    }
  `,
  collaborative: gql`
    query collaborative($id: ID!) {
      collaborative(id: $id) {
        id
        company {
          text
        }
        dev_date
        signoff_date
        remark
        budget
        server_recurrent
        server_fees
        projects {
          views {
            id
          }
          name
          is_client
          status
          ci_cd
          server {
            ip
            name
            vendor
          }
          db_host_connect {
            ip
          }
          db_struct
          project_relation {
            name
          }
          port
          project_type {
            text
          }
          domain
          website_type {
            text
          }
          remark
        }
        owner {
          name
          contact
          email
          country {
            text
          }
          role
          gender
        }
        created_at
      }
    }
  `,
  frontendStatistics: gql`
    query frontendStatistics($where: JSON) {
      frontendStatistics(where: $where) {
        id
      }
    }
  `,
  user_test: gql`
    query user {
      user @client
    }
  `,
};

export const mutation = {
  login: gql`
    mutation login($input: UsersPermissionsLoginInput!) {
      login(input: $input) {
        jwt
        user {
          id
          username
        }
      }
    }
  `,
  register: gql`
    mutation register($input: UserInput!) {
      register(input: $input) {
        jwt
        user {
          id
          username
        }
      }
    }
  `,
};

// export const GatsbyQuery = graphql`
//   {
//     babySteps {
//       countries {
//         text
//       }
//     }
//   }
// `;
// const { loading, error, data } = useQuery(APOLLO_QUERY);
